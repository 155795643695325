.page_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main_container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  padding-top: 2rem;

  @media screen and (max-width: 480px) {
    margin-left: 1rem;
    margin-right: 1rem;
    width: auto;
    padding-top: 0;
  }
}
