.page_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main_container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1400px;
  padding-top: 2rem;

  @media screen and (max-width: 480px) {
    margin-left: 1rem;
    margin-right: 1rem;
    width: auto;
    padding-top: 0;
  }
}

.share_container {
  display: flex;
  & > button {
    margin-right: 1rem;
  }
}

.picture_container {
  display: flex;
  align-items: center;
}

.picture {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-right: 2rem;
}
