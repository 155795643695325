.header_container {
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;

  @media screen and (max-width: 480px) {
    align-items: center;
    justify-content: center;

    & a:not(.logo_link) {
      display: none;
    }
  }

  & img {
    height: 60px;
    width: auto;
  }

  & a {
    color: white;
    text-decoration: none;
    font-weight: bolder;

    &:hover {
      opacity: 0.8;
    }
  }
}
