.page_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main_container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1400px;
  padding-top: 1rem;

  @media screen and (max-width: 480px) {
    margin-left: 1rem;
    margin-right: 1rem;
    width: auto;
    padding-top: 0;
  }

  & .col_content,
  .col_form {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  & .col_content {
    display: flex;
    flex-direction: column;

    & h1 {
      margin-top: 1rem;
      font-size: 2.8rem;
    }

    & .higlight_title {
      background-color: white;
      color: #333;
    }
  }
}

.banner {
  background-color: white;
  color: black;
  width: 100%;
  margin-top: 5rem;
  padding: 2rem;
  display: flex;
  justify-content: center;

  & h2 {
    color: black;
  }
}

.grid_container {
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    overflow-x: scroll;
    height: 200px;
    width: 100%;

    .grid_item {
      max-width: 240px;
    }
  }

  .grid_item {
    flex: 1;
    min-width: 180px;
    min-height: 170px;
    margin: 0 1rem 1rem 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 300ms;
    position: relative;
    border-radius: 2px;
    outline: solid 1px rgba(255, 255, 255, 0.4);
    overflow: hidden;
    color: rgba(255, 255, 255, 0);
    // box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);

    display: flex;
    align-items: flex-start;
    flex-direction: column;

    & p,
    span {
      position: relative;
      z-index: 999;
      margin-left: 0.7rem;
      margin-right: 0.7rem;
      font-size: 0.9rem;
    }

    & .tags {
      margin-top: 0.7rem;
      margin-bottom: auto;
      // background-color: rgba(255, 255, 255, 0.6);
      color: white;
    }

    & > .overlay {
      // z-index: -1;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.33) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      transition: all 300ms;
    }

    &:hover {
      transform: scale(1.05);
      color: white;

      & > .overlay {
        // background-color: transparent;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.8) 0%,
          rgba(0, 0, 0, 0.3) 100%
        );
      }

      & .tags {
        color: #b09172;
        background-color: white;
      }

      & p {
        font-weight: bolder;
      }
    }
  }
}

.donation_form_container {
  margin-left: 4rem;
  background-color: white;
  border-radius: 5px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.2);
  padding: 1rem 2rem 2rem 2rem;
  color: #666;

  display: flex;
  flex-direction: column;

  & .title_container {
    display: flex;
    justify-content: space-between;
    column-gap: 12px;
    flex-wrap: wrap;
    margin-bottom: 16px;
  }

  @media screen and (max-width: 480px) {
    margin-left: 0;
  }

  & .helper {
    line-height: 1.5rem;
  }

  & h2 {
    color: #333;
    margin: 0.8rem 0;
  }

  & .amount_block {
    border-radius: 5px;
    border: solid 1px rgba(0, 0, 0, 0.4);
    padding: 0.5rem;
    text-align: center;
  }

  & .tags_container {
    display: flex;
    flex-wrap: wrap;
  }

  & .targetV2 {
    margin-right: 8px;
    line-height: 1.5rem;
    &:not(:last-child)::after {
      content: " • ";
    }
  }

  & .target_tag {
    transition: all 300ms;

    &.selected_target {
      background-color: #b09172;
      color: white;
      border: solid 1px rgba(0, 0, 0, 0.4);
    }

    &:hover {
      border: solid 1px rgba(0, 0, 0, 0.4);
    }

    cursor: pointer;
    border-radius: 33px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    padding: 0.5rem 1rem;
    display: flex;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }

  & .amount_tag {
    transition: all 300ms;

    & .monthly_amount {
      font-size: 1.5rem;
      font-weight: bold;
    }

    font-size: 0.8rem;

    &.selected_amount {
      background-color: #b09172;
      color: white;
      border: solid 1px rgba(0, 0, 0, 0.4);
    }

    &:hover {
      border: solid 1px rgba(0, 0, 0, 0.4);
    }

    cursor: pointer;
    flex: 1;
    border-radius: 5px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    padding: 0.8rem;
    display: flex;
    flex-direction: column;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;

    &:last-of-type {
      margin-right: 0;
    }
  }

  & .real_cost {
    font-weight: bolder;
  }
}

.confirm_button {
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  font-weight: bold;
  border: none;
  background-color: #b09172;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 300ms;
  &:hover {
    background-color: #333;
    color: white;
    transform: scale(1.1);
  }
  & img {
    margin-left: 1rem;
  }
}

.see_plus_button {
  margin-top: 2rem;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  font-weight: bold;
  border: none;
  background-color: white;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  color: #199acc;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 300ms;
  &:hover {
    background-color: #333;
    color: white;
    transform: scale(1.1);
  }
  & img {
    margin-left: 1rem;
  }
}

.about {
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  & a {
    text-decoration: none;
  }
}

.youtube_thumbnails {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  & > div {
    margin-right: 20px;

    & iframe {
      max-width: 80vw;
    }
  }
}
