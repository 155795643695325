.footer_container {
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: 5rem 0;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > div {
      padding-left: 1rem;
      padding-right: 1rem;

      text-align: center;
    }
  }

  & .icon {
    margin: 0.4rem;
  }

  & a {
    color: white;
    text-decoration: none;
    // font-weight: bolder;

    &:hover {
      opacity: 0.8;
    }
  }
}
